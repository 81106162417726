import _ from "lodash";
import { useSelector } from "react-redux";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import CountdownText from "../common/CountdownText";
import AuthState from "../../utils/common/auth-state";
import React, { forwardRef, useImperativeHandle } from "react";
import { arrowRedoOutline, sendOutline } from "ionicons/icons";
import { getRandomColorName } from "../../utils/common/helper";
import { server_send_message } from "../../socket-polling-emits";
import { CLIENT_CHAT_EVENTS } from "@com.xcodeclazz/socket-polling";
import {
  IonRow,
  IonCol,
  IonText,
  IonItem,
  IonList,
  IonGrid,
  IonIcon,
  IonInput,
  IonButton,
  IonButtons,
} from "@ionic/react";

export interface ISectionChats {}

const SectionChats = forwardRef<ISectionChats, 
{
  selected: BatchAttrs;
}>((props, ref) => {
  useImperativeHandle(ref, () => ({}));

  const authState = new AuthState();
  const inputRef = React.createRef<HTMLIonInputElement>();
  const chats: CLIENT_CHAT_EVENTS.YIELD_CHAT_HISTORY = useSelector((state: any) => state.chatState);

  const sendMessage = () => {
    const message = inputRef.current?.value?.toString();
    if (!message) return;
    server_send_message({ 
      body: message,
      created: Date.now(),
      name: authState.getUser()?.name || "",
    });
    inputRef.current!.value = "";
  };

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") sendMessage();
  };

  return (
    <>
      <IonList className="h-full overflow-scroll pb-20" lines="none">
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="10">
                <IonInput ref={inputRef} className="font-bold" onKeyPress={handleEnterKey} placeholder="Write your message here..." />
              </IonCol>
              <IonCol size="2" className="flex flex-col items-center justify-center">
                <IonButtons>
                  <IonButton><CountdownText seconds={30} onTimeUp={() => console.log('show button again')} /></IonButton>
                  <IonButton title="send" onClick={sendMessage}><IonIcon slot="icon-only" size="small" icon={sendOutline} /></IonButton>
                </IonButtons>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        {_.map(chats.history, (el, idx) => (
          <IonItem key={idx} button>
            <IonGrid>
              <IonRow>
                <IonCol size="10">
                  <IonGrid>
                    <IonRow>
                      <div>
                        <IonText color={getRandomColorName()}>{el.name}</IonText>{" "}
                        <IonText className="font-bold">{el.body}</IonText>
                      </div>
                    </IonRow>
                  </IonGrid>
                </IonCol>
                <IonCol size="2" className="flex flex-col justify-start items-end">
                  <IonButtons>
                    <IonButton title="reply" size="small">
                      <IonIcon icon={arrowRedoOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        ))}
      </IonList>
    </>
  );
});

export default SectionChats;
