import { BroadcastAttrs, CLIENT_BROADCAST_EVENTS } from "@com.xcodeclazz/socket-polling";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const broadcastState = createSlice({
  name: "broadcastState",
  initialState: {
    history: [] as BroadcastAttrs[],
  },
  reducers: {
    saveBroadcastHistory: (state, action) => {
      const broadcast: CLIENT_BROADCAST_EVENTS.YIELD_BROADCAST = action.payload;
      state.history.unshift(broadcast);
    },
    saveBroadcastHistories: (state, action) => {
      const data: CLIENT_BROADCAST_EVENTS.YIELD_BROADCAST_HISTORY = action.payload;
      if (data.history) state.history = _.reverse(_.cloneDeep(data.history));
    },
    clearBroadcastHistories: (state) => {
      state.history = [];
    },
  },
});

export const {
  saveBroadcastHistory,
  saveBroadcastHistories,
  clearBroadcastHistories,
} = broadcastState.actions;

export default broadcastState.reducer;
