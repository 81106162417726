import _ from "lodash";
import AceEditor from "react-ace";
import { useSelector } from "react-redux";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import { server_answer } from "../../../socket-polling-emits";
import { QuestionAttrs } from "@com.xcodeclazz/socket-polling";
import { getEditorTheme } from "../../../utils/common/helper";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { IonCard, IonButton, IonCardTitle, IonCardHeader, IonCardContent, IonCardSubtitle } from "@ionic/react";

export interface IInputQuestionIPC {}

const InputQuestion = forwardRef<
  IInputQuestionIPC,
  {
    askedQuestion: QuestionAttrs;
    selected: BatchAttrs;
  }
>((props, ref) => {
  useImperativeHandle(ref, () => ({}));

  const aceEditorRef = React.createRef<AceEditor>();
  const theme_state: string = useSelector((state: any) => state.uiState.theme);
  const [source, setSource] = useState<string>("");

  const [fontSize, setFontSize] = useState<number>(18);
  const [showGutter, setShowGutter] = useState<boolean>(false);

  useEffect(() => {}, [theme_state]);

  const onChangeHandler = (source: string) => setSource(source);
  const onSubmitHandler = () => {
    console.log(source);
    if (source) server_answer({ answer: source, qid: props.askedQuestion.id });
  };

  return (
    <IonCard className="w-full h-full ion-no-margin rounded-none">
      <IonCardHeader>
        <IonCardTitle>
          <section className="flex flex-row justify-between items-center">
            {props.askedQuestion.text}
            <IonButton onClick={onSubmitHandler} size="small" fill="clear">Submit</IonButton>
          </section>
        </IonCardTitle>
        <IonCardSubtitle>Scroll the options if required</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className="h-48 overflow-scroll">
        <AceEditor
          ref={aceEditorRef}
          name={`ID-${Math.random()}`}
          mode={"text"}
          width="100"
          theme={getEditorTheme()}
          fontSize={fontSize}
          highlightActiveLine={true}
          showPrintMargin={true}
          readOnly={false}
          showGutter={showGutter}
          wrapEnabled={false}
          editorProps={{ $blockScrolling: true }}
          onLoad={(editor) => {
            editor.renderer.setPadding(10);
          }}
          style={{ height: "100%", backgroundColor: "transparent" }}
          placeholder={`© xCodeClazz 2021 - ${new Date().getFullYear()}`}
          value={`${source}`}
          // debounceChangePeriod={1000}
          onChange={onChangeHandler}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            showLineNumbers: false,
            tabSize: 2,
          }}
        />
      </IonCardContent>
    </IonCard>
  );
});

export default InputQuestion;
