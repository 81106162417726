import _ from "lodash";
import AceEditor from "react-ace";
import { useSelector } from "react-redux";
import { Dialog } from "@capacitor/dialog";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import RunModal, { IRunModal } from "./sheets/RunModal";
import { getEditorTheme } from "../../utils/common/helper";
import EditorState, { ISavedCode } from "../../utils/algorithm/editor-state";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IonRow, IonCol, IonGrid, IonIcon, IonButton, IonSpinner } from "@ionic/react";
import { ILanguage } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import { alertCircleOutline, listOutline, playOutline, refreshOutline } from "ionicons/icons";

export interface ISectionCodingEditor {}

const SectionCodingEditor = forwardRef<ISectionCodingEditor, 
{
  selected: BatchAttrs;
}>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({}));

    const editorState = new EditorState();
    const runModalRef = React.createRef<IRunModal>();
    const aceEditorRef = React.createRef<AceEditor>();
    const codingState: any = useSelector((state: any) => state.codingState);
    const theme_state: string = useSelector((state: any) => state.uiState.theme);

    const [fontSize, setFontSize] = useState<number>(18);
    const [showGutter, setShowGutter] = useState<boolean>(false);
    const [selectedLang, setSelectedLang] = useState<ILanguage>(codingState?.codeTask?.lang as ILanguage || props?.selected?.meta?.lang as ILanguage || ILanguage.Python);
    const [source, setSource] = useState<ISavedCode>(editorState.getSavedCodeFor(selectedLang, '-section'));

    useEffect(() => {}, [theme_state]);
    useEffect(() => { if (props.selected?.meta?.lang) setSelectedLang(props.selected?.meta?.lang as ILanguage); }, [props.selected]);
    useEffect(() => setSource(editorState.getSavedCodeFor(selectedLang, '-section')), [selectedLang, codingState]);
    useEffect(() => editorState.saveCode(source.key, source), [source?.code]);
    useEffect(() => {
      document.addEventListener('keyup', onHotkeysHandler, false);
      return () => document.removeEventListener('keyup', onHotkeysHandler);
    });

    const onStageCode = () => runModalRef.current?.stageCode(selectedLang, source?.code);
    const onHotkeysHandler = (e: KeyboardEvent) => { if (e.ctrlKey && e.key === 'x') onStageCode(); };
    const resetCode = async () => {
      const { value } = await Dialog.confirm({ title: 'Reset', message: 'Do you really want to reset everything?' });
      if (value) setSource(editorState.getSampleCodeFor(selectedLang));
    };

    return (
      <>
        <IonGrid>
          <IonRow className="items-center">
            <IonCol className="hidden md:block" size="3" sizeSm="2" sizeLg="1">
              <IonButton size="small" title="Show/hide line numbers" fill="clear" onClick={(e) => setShowGutter(!showGutter)}>
                <IonIcon icon={listOutline} slot="icon-only" />
              </IonButton>
            </IonCol>
            <IonCol className="hidden md:block" size="3" sizeSm="2" sizeLg="1">
              <IonButton size="small" title="Reset" fill="clear" onClick={resetCode}>
                <IonIcon icon={refreshOutline} slot="icon-only" />
              </IonButton>
            </IonCol>
            <IonCol className="hidden md:block" size="3" sizeSm="2" sizeLg="1">
              <IonButton size="small" title="Test code" fill="clear" onClick={onStageCode}>
                <IonIcon icon={playOutline} slot="icon-only" />
              </IonButton>
            </IonCol>
            <IonCol className="hidden md:block" size="3" sizeSm="2" sizeLg="1">
              <IonButton size="small" title="Raisehand" fill="clear" onClick={() => console.log("Raise an issue with attached code snippet")}>
                <IonIcon icon={alertCircleOutline} slot="icon-only" />
              </IonButton>
            </IonCol>
            {codingState?.isEditorFreezed && <IonCol push="7" size="1"><IonSpinner name="dots" /></IonCol>}
          </IonRow>
        </IonGrid>
        <AceEditor
          ref={aceEditorRef}
          name={`ID-${Math.random()}`}
          mode={editorState.getMode(selectedLang)}
          width="100"
          theme={getEditorTheme()}
          fontSize={fontSize}
          highlightActiveLine={true}
          showPrintMargin={true}
          readOnly={codingState?.isEditorFreezed}
          showGutter={showGutter}
          wrapEnabled={false}
          editorProps={{ $blockScrolling: true }}
          onLoad={(editor) => { editor.renderer.setPadding(10) }}
          style={{ height: "100%", backgroundColor: "transparent" }}
          placeholder={`© xCodeClazz 2021 - ${new Date().getFullYear()}`}
          value={`${source?.code}`}
          // debounceChangePeriod={1000}
          onChange={(code: string) => setSource({ ...source, code })}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
        <RunModal ref={runModalRef}  />
      </>
    );
  }
);

export default SectionCodingEditor;
