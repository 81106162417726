import _ from "lodash";
import Joi from "joi";
import { useHistory } from "react-router";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGreetUserMessage } from "../utils/common/helper";
import { errorToast, getBatchMyBatches, postBatchLogin } from "../apis";
import { saveBatches, selectedBatch } from "../redux/reducers/batcheState";
import { BatchAttrs, BatchPayloadJoi_Login, BatchPayload_Login } from "@com.xcodeclazz/batch";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonInput, IonPage, IonRow, IonSelect, IonSelectOption } from "@ionic/react";

const Dashboard: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const usernameRef = React.createRef<HTMLIonInputElement>();
  const passwordRef = React.createRef<HTMLIonInputElement>();

  const selected: BatchAttrs = useSelector((state: any) => state.batchState.selected);
  const batches: BatchAttrs[] = useSelector((state: any) => state.batchState.batches);

  useEffect(() => {
    setLoading(true);
    getBatchMyBatches((response) => {
      dispatch(saveBatches(response.data));
      setLoading(false);
    }, (error) => {
      errorToast(error);
      setLoading(false);
    });
  }, []);

  const onLoginHandler = () => {
    const username = usernameRef.current?.value?.toString();
    const password = passwordRef.current?.value?.toString();
    if (username == "" || password == "") return;

    const data: BatchPayload_Login = { username: username || "", password: password || "" };

    const { error, value } = Joi.object(BatchPayloadJoi_Login).validate(data);
    if (!error) {
      setLoading(true);
      postBatchLogin(value, (response) => {
        setLoading(false);
        history.push(`/meet?token=${response.data.token}`);
      }, (error) => {
        errorToast(error);
        setLoading(false);
      });
    }
  };

  const onEnterHandler = () => {
    if (!selected || !selected.id) return;
    if (!selected.isLive) return Capacitor.toast(`${selected.batchName}, is not live 😔`);
    history.push(`/meet?batch=${selected.id}`);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <IonPage>
      <Header themeBtn />
      <IonContent fullscreen className="ion-padding">
        <IonGrid className="h-full space-y-10">
          <IonRow>
            <IonCol>
              <h1 className="text-4xl font-bold">{getGreetUserMessage()}</h1>
            </IonCol>
          </IonRow>
          <IonRow className="h-full md:h-1/2">
            <IonCol size="12" pushLg="2" sizeMd="12" sizeLg="8">
              <IonCard className="h-full ion-no-margin rounded-xl">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" sizeMd="6">
                      <IonCard className="shadow-none space-y-2">
                        <IonCardHeader>
                          <IonCardTitle>Your Batches</IonCardTitle>
                          <IonCardSubtitle>If you don't see live classes, please refresh the page.</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent className="space-y-5">
                          <IonSelect justify="end" label="Join Stream" labelPlacement="floating" fill="outline" selectedText={selected?.batchName} value={selected} onIonChange={e => dispatch(selectedBatch(e.target.value))}>
                            {_.map(batches, (el, idx) => (<IonSelectOption key={idx} value={el}>{el?.batchName} {el?.isLive && '[Live]'}</IonSelectOption>))}
                          </IonSelect>
                          <IonButton onClick={onEnterHandler}>Enter</IonButton>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size="12" sizeMd="6">
                      <IonCard className="shadow-none">
                        <IonCardHeader>
                          <IonCardTitle>Login</IonCardTitle>
                          <IonCardSubtitle>You can join restricted stream using credentials</IonCardSubtitle>
                        </IonCardHeader>
                        <IonGrid>
                          <IonRow><IonCol><IonInput ref={usernameRef} fill="outline" placeholder="Username" type="text" /></IonCol></IonRow>
                          <IonRow><IonCol><IonInput ref={passwordRef} fill="outline" placeholder="Password" type="password" /></IonCol></IonRow>
                          <IonRow><IonCol><IonButton onClick={onLoginHandler}>Login</IonButton></IonCol></IonRow>
                        </IonGrid>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
