import _ from "lodash";
import { compiler } from "../../../apis";
import { IResult } from "@com.xcodeclazz/compile-run-v2";
import { getBeforeDecimalPoint } from "../../../utils/common/helper";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { ILanguage } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import { playOutline, closeOutline, languageOutline, hourglassOutline } from "ionicons/icons";
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonSpinner, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";

export interface IRunModal {
  stageCode: (lang: ILanguage, src: string) => void;
  getResult: () => IResult | undefined;
}

const RunModal = forwardRef<IRunModal, {}>((props, ref) => {
  const modalInputRef = React.createRef<HTMLIonTextareaElement>();
  const [waitForCode, setWaitForCode] = useState<boolean>(false);
  const [codeLang, setCodeLang] = useState<ILanguage>();
  const [content, setContent] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);
  const [result, setResult] = useState<IResult>();

  useImperativeHandle(ref, () => ({
    getResult: () => result,
    stageCode: (lang: ILanguage, src: string) => {
      setCodeLang(lang);
      setContent(src);
      if (!isOpen) 
        setOpen(true);
    },
  }));

  const runCode = () => {
    setWaitForCode(true);
    const input = modalInputRef.current?.value || "";
    if (codeLang) {
      compiler({ input, content, lang: codeLang }, (res, error) => {
        if (error) console.log(error);
        if (res) setResult(res);
        setWaitForCode(false);
      });
    }
  };

  return (
    <IonModal isOpen={isOpen} initialBreakpoint={0.45} breakpoints={[0, 0.25, 0.5, 0.75]} onDidDismiss={(e) => setOpen(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <b>x</b>Console
          </IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={(e) => setOpen(false)}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={runCode}>
              <IonIcon icon={playOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <section className="flex flex-col space-y-2">
          <IonItem lines="none">
            <IonTextarea
              placeholder="Provide some input [Optional]"
              ref={modalInputRef}
              spellCheck={false}
              autoGrow={true}
            />
          </IonItem>
          {waitForCode && (
            <IonItem lines="none">
              <IonLabel>Processing...</IonLabel>
              <IonSpinner name="dots" />
            </IonItem>
          )}
          <IonItem lines="none">
            <IonTextarea
              wrap="off"
              readonly={true}
              disabled={true}
              autoGrow={true}
              spellCheck={false}
              value={result?.executionResult?.stdout || result?.executionResult?.stderr}
            ></IonTextarea>
          </IonItem>
        </section>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton title="language" slot="start" disabled>
              <IonIcon slot="start" icon={languageOutline} />
              <small className="text-xs capitalize">{codeLang}</small>
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton title="miliseconds" slot="start" disabled>
              <IonIcon slot="start" icon={hourglassOutline} />
              <small className="text-xs">{getBeforeDecimalPoint(result?.ms)} ms</small>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
});

export default RunModal;
