import _ from "lodash";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import socketPolling from "../../socket-polling";
import React, { useEffect, useState } from "react";
import Countdown, { ICountdown } from "./Countdown";
import AuthState from "../../utils/common/auth-state";
import TimerClass from "../../utils/common/timer-state";
import { setTheme } from "../../redux/reducers/uiState";
import { isDark, toggleDark } from "../../utils/common/helper";
import { CLIENT_TIMER_EVENTS } from "@com.xcodeclazz/socket-polling";
import FingerprintState from "../../utils/common/fingerprint-state";
import { PREFERENCE_KEYS, setPreference } from "../../utils/common/cache";
import { codeOutline, moonOutline, settingsOutline } from "ionicons/icons";
import {
  IonIcon,
  IonTitle,
  IonButton,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";

const Header: React.FC<{
  dashboard?: string;

  settingIcon?: boolean;
  logoutIcon?: boolean;
  themeBtn?: boolean;
  codeIcon?: boolean;
  backBtn?: string;
}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fp = new FingerprintState();
  const authState = new AuthState();
  const timerState = new TimerClass();
  const countdownRef = React.createRef<ICountdown>();
  const [running, setRunning] = useState<boolean>(false);
  const [timer, setTimer] = useState<CLIENT_TIMER_EVENTS.YIELD_TIMER_START>({ iat: Date.now(), title: "?" });

  useEffect(() => {
    const timer = timerState.getTimer();
    if (timerState.isPrevTimeLeft()) {
      setRunning(true);
      setTimer({ title: timer?.title || "?", iat: timer?.iat || Date.now() });
    }
  }, []);

  const timerStartListener = (response: CLIENT_TIMER_EVENTS.YIELD_TIMER_START) => {
    timerState.saveTimer(response);
    setRunning(true);
    setTimer(response);
  };

  const timerStopListener = (response: CLIENT_TIMER_EVENTS.YIELD_TIMER_STOP) => {
    timerState.saveTimer({ iat: new Date(), title: "?" });
    setRunning(false);
  };

  useEffect(() => {
    socketPolling.on(CLIENT_TIMER_EVENTS.ALL.YIELD_TIMER_START, timerStartListener);
    socketPolling.on(CLIENT_TIMER_EVENTS.ALL.YIELD_TIMER_STOP, timerStopListener);
    return () => {
      socketPolling.off(CLIENT_TIMER_EVENTS.ALL.YIELD_TIMER_START, timerStartListener);
      socketPolling.off(CLIENT_TIMER_EVENTS.ALL.YIELD_TIMER_STOP, timerStopListener);
    };
  }, [countdownRef]);

  return (
    <IonHeader>
      <IonToolbar>
        {props.backBtn && (
          <IonButtons slot="start" className="md:hidden">
            <IonBackButton defaultHref={props.backBtn}></IonBackButton>
          </IonButtons>
        )}
        <IonTitle>
          <strong className="text-yellow-500">x</strong>
          <strong className="cursor-pointer" onClick={(e) => history.push("/")}>
            CodeClazz Live
          </strong>
        </IonTitle>
        <IonButtons slot="end">
          {running && <Countdown futureTime={new Date(timer.iat)} reason={timer.title} onTimeUp={() => setRunning(false)} />}
          {authState.validateUser() ? (
            <>
              {props.dashboard && (
                <IonButton routerDirection="none" routerLink={props.dashboard}>
                  Dashboard
                </IonButton>
              )}
            </>
          ) : (
            <>
              {/* <IonButton routerDirection="none" routerLink="/login">
                Login
              </IonButton> */}
            </>
          )}
          {props.settingIcon && (
            <IonButton routerDirection="none" routerLink="/settings">
              <IonIcon slot="icon-only" icon={settingsOutline} />
            </IonButton>
          )}
          {props.codeIcon && (
            <IonButton target="__blank" href="https://code.xcodeclazz.com">
              <IonIcon slot="icon-only" icon={codeOutline} />
            </IonButton>
          )}
          {props.themeBtn && (
            <IonButton
              title="theme"
              onClick={(e) => {
                toggleDark();
                setPreference(PREFERENCE_KEYS.DARK_MODE, `${isDark()}`);
                dispatch(setTheme({ theme: `${isDark()}` }));
              }}
            >
              <IonIcon slot="icon-only" icon={moonOutline} />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
