import _ from "lodash";
import React, { useEffect } from "react";
import Header from "../components/common/Header";
import { IonContent, IonPage } from "@ionic/react";

const Settings: React.FC<{}> = (props) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <strong>Settings Page</strong>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
