import socketPolling from "./socket-polling";
import {
  IRoomPayload,
  IChatPayload,
  IFocusPayload,
  ILubDubPayload,
  IRaisehandPayload,
  SERVER_ROOM_EVENTS,
  SERVER_CHAT_EVENTS,
  SERVER_FOCUS_EVENTS,
  SERVER_CODING_EVENTS,
  SERVER_QUESTION_EVENTS,
  SERVER_RAISEHAND_EVENTS,
  SERVER_HEARTBEAT_EVENTS,
  IQuestionAnsweredPayload,
  ICodeOutputSubmitPayload,
} from "@com.xcodeclazz/socket-polling";

export const server_heartbeat_lub_dub = (payload: ILubDubPayload) => {
  socketPolling.emit(SERVER_HEARTBEAT_EVENTS.ALL.CLIENT_LUB_DUB, payload);
};

export const server_join = (payload: IRoomPayload) => {
  socketPolling.emit(SERVER_ROOM_EVENTS.GUEST.CLIENT_JOIN, payload);
};

export const server_submit_code_output = (payload: ICodeOutputSubmitPayload) => {
  socketPolling.emit(SERVER_CODING_EVENTS.GUEST.CLIENT_SUBMIT_OUTPUT, payload);
};

export const server_chat_history = () => {
  socketPolling.emit(SERVER_CHAT_EVENTS.ALL.CLIENT_CHAT_HISTORY, {});
};

export const server_question_history = () => {
  socketPolling.emit(SERVER_QUESTION_EVENTS.ALL.CLIENT_QUESTION_HISTORY, {});
};

export const server_raisehand_history = () => {
  socketPolling.emit(SERVER_RAISEHAND_EVENTS.ALL.CLIENT_RAISEHAND_HISTORY, {});
};

export const server_client_count = () => {
  socketPolling.emit(SERVER_ROOM_EVENTS.ALL.CLIENT_TOTAL_CLIENT_COUNT, {});
};

export const server_send_message = (payload: IChatPayload) => {
  socketPolling.emit(SERVER_CHAT_EVENTS.GUEST.CLIENT_CHAT_MESSAGE, payload);
};

export const server_raisehand = (payload: IRaisehandPayload) => {
  socketPolling.emit(SERVER_RAISEHAND_EVENTS.GUEST.CLIENT_RAISEHAND, payload);
};

export const server_answer = (payload: IQuestionAnsweredPayload) => {
  socketPolling.emit(SERVER_QUESTION_EVENTS.GUEST.CLIENT_ANSWERED, payload);
};

export const server_focus_on = (payload: IFocusPayload) => {
  socketPolling.emit(SERVER_FOCUS_EVENTS.GUEST.CLIENT_FOCUS_ON, payload);
};

export const server_focus_off = (payload: IFocusPayload) => {
  socketPolling.emit(SERVER_FOCUS_EVENTS.GUEST.CLIENT_FOCUS_OFF, payload);
};
