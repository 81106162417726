import _ from "lodash";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import { server_answer } from "../../../socket-polling-emits";
import { QuestionAttrs } from "@com.xcodeclazz/socket-polling";
import { forwardRef, useImperativeHandle, useState } from "react";
import {
  IonCard,
  IonList,
  IonItem,
  IonButton,
  IonCheckbox,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  CheckboxCustomEvent,
} from "@ionic/react";

export interface IMcqQuestionIPC {}

const McqQuestion = forwardRef<
  IMcqQuestionIPC,
  {
    askedQuestion: QuestionAttrs;
    selected: BatchAttrs;
  }
>((props, ref) => {
  useImperativeHandle(ref, () => ({}));
  const [selected, setSelected] = useState<number[]>([]);

  const changeHandler = _.debounce((e: CheckboxCustomEvent, index: number) => {
    const options = _.cloneDeep(selected);
    const position = selected.indexOf(index);
    if (e.target.checked) options.push(index);
    else if (position !== -1) options.splice(position, 1);
    setSelected(options);
  });

  const onSubmitHandler = () => {
    if (selected) server_answer({ answer: JSON.stringify(selected), qid: props.askedQuestion.id });
  };

  return (
    <IonCard className="w-full h-full ion-no-margin rounded-none">
      <IonCardHeader>
        <IonCardTitle>
          <section className="flex flex-row justify-between items-center">
            {props.askedQuestion.text}
            <IonButton onClick={onSubmitHandler} size="small" fill="clear">Submit</IonButton>
          </section>
        </IonCardTitle>
        <IonCardSubtitle>Scroll the options if required</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className="h-48 overflow-scroll">
        <IonList>
          {_.map(props.askedQuestion.mcq, (el, idx) => (
            <IonItem key={idx}>
              <pre className="p-0 m-0" dangerouslySetInnerHTML={{ __html: el.text }} />
              <IonCheckbox slot="end" value={el.text} onIonChange={e => changeHandler(e, idx)} />
            </IonItem>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
});

export default McqQuestion;
