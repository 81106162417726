import { BatchAttrs, BatchResponse_ShowMyBatches } from "@com.xcodeclazz/batch";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const batchState = createSlice({
  name: "batchState",
  initialState: {
    batches: [] as BatchAttrs[],
    selected: undefined as BatchAttrs | undefined
  },
  reducers: {
    saveBatches: (state, action) => {
      const batches: BatchResponse_ShowMyBatches = action.payload;
      state.batches = batches.batches;
    },
    selectedBatch: (state, action) => {
      const batch: BatchAttrs = action.payload;
      state.selected = batch;
    },
    clearBatchState: (state) => {
      state.batches = [];
      state.selected = undefined;
    },
  },
});

export const { saveBatches, selectedBatch, clearBatchState } = batchState.actions;

export default batchState.reducer;
