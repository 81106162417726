import _ from "lodash";
import { useSelector } from "react-redux";
import { peopleOutline } from "ionicons/icons";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import { forwardRef, useImperativeHandle } from "react";
import { CLIENT_BROADCAST_EVENTS } from "@com.xcodeclazz/socket-polling";
import {
  IonCard,
  IonText,
  IonIcon,
  IonButton,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonSkeletonText,
} from "@ionic/react";

export interface IDescriptionPanelIPC {}

const DescriptionPanel = forwardRef<
  IDescriptionPanelIPC,
  {
    selected: BatchAttrs
  }
>(({ selected }, ref) => {
  useImperativeHandle(ref, () => ({}));

  const broadcastState: CLIENT_BROADCAST_EVENTS.YIELD_BROADCAST_HISTORY =
    useSelector((state: any) => state.broadcastState);

  return (
    <IonCard className="w-full h-full ion-no-margin rounded-none">
      <IonCardHeader>
        <section className="flex flex-row justify-between items-center">
          <div>
            <IonCardTitle>{selected?.batchName}</IonCardTitle>
            <IonCardSubtitle>{selected?.batchMentorName}</IonCardSubtitle>
          </div>
          <div>
            <IonButton fill="clear" disabled size="small">
              <IonIcon icon={peopleOutline} slot="start" /> {selected?.users?.length}
            </IonButton>
          </div>
        </section>
      </IonCardHeader>
      <IonCardContent className="h-48 overflow-scroll">
        {broadcastState.history.length === 0 ? (
          <IonText>
            <IonSkeletonText animated className="w-80" />
            <IonSkeletonText animated className="w-44" />
            <IonSkeletonText animated className="w-44" />
          </IonText>
        ) : (
          <pre
            className="text-lg font-bold"
            dangerouslySetInnerHTML={{ __html: _.first(broadcastState.history)?.body || "" }}
          />
        )}
      </IonCardContent>
    </IonCard>
  );
});

export default DescriptionPanel;
