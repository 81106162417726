const backend = process.env.REACT_APP_SERVER_BACKEND_URL || ''; // proxy
const codereport = process.env.REACT_APP_SERVER_CODE_REPORT_URL || 'http://localhost:9999/'; // proxy
const monolithic = process.env.REACT_APP_SERVER_MONOLITHIC_URL || 'http://localhost:8087/';
const compilers = process.env.REACT_APP_SERVER_COMPILERS_URL || 'http://localhost:5000/';
const batch = process.env.REACT_APP_SERVER_BATCH_URL || 'http://localhost:8090/';
const rtmps = process.env.REACT_APP_SERVER_RTMPS_URL || 'http://localhost:7000/';
const meets = process.env.REACT_APP_SERVER_MEETS_URL || 'https://meet.google.com/';
const discord = process.env.REACT_APP_SERVER_DISCORD_URL || 'https://discord.gg/';
const zoom = process.env.REACT_APP_SERVER_ZOOM_URL || 'https://us05web.zoom.us/j/82218660260?pwd=xyz.1';

export const routes = {
  POST_COMPILERS_CPP: `${compilers}api/compilers/cpp`,
  POST_COMPILERS_JAVA: `${compilers}api/compilers/java`,
  POST_COMPILERS_NODE: `${compilers}api/compilers/node`,
  POST_COMPILERS_PYTHON: `${compilers}api/compilers/python`,

  POST_BATCH_LOGIN: `${batch}api/batch/login`,
  POST_BATCH_ISALLOWED: `${batch}api/batch/isallowed`,
  GET_BATCH_MY_BATCHES: `${batch}api/batch/batches/my`,
  GET_BATCH_BATCH_STRENGTH: `${batch}api/batch/strength/:batch`,
  GET_BATCH_BATCH_BY_COURSE: `${batch}api/batch/course/:course`,
  GET_BATCH_SHOW_BATCH: `${batch}api/batch/:batch`,

  // POST: https://code-report.xcodeclazz.com/stage/:id
  // GET: https://code-report.xcodeclazz.com/stage/:id
  // POST: https://code-report.xcodeclazz.com/:id
  // GET: https://code-report.xcodeclazz.com/:id
  POST_CODE_REPORT: `${codereport}:id`,
  GET_CODE_REPORT: `${codereport}:id`
};