import _ from "lodash";
import { useSelector } from "react-redux";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import { forwardRef, useImperativeHandle } from "react";
import { getRandomColorName } from "../../utils/common/helper";
import { CLIENT_QUESTION_EVENTS } from "@com.xcodeclazz/socket-polling";
import {
  IonCol,
  IonRow,
  IonText,
  IonItem,
  IonList,
  IonGrid,
  IonTextarea,
  IonProgressBar,
} from "@ionic/react";

export interface ISectionQuestionHistory {}

const getLineCodeLinesHeight = (code: string) => code ? code.split("\n").length : 3;
const SectionHistory = forwardRef<ISectionQuestionHistory, 
{
  selected: BatchAttrs;
}>((props, ref) => {
  useImperativeHandle(ref, () => ({}));

  const questions: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_HISTORY = useSelector((state: any) => state.questionState);

  return (
    <>
      <IonList className="h-full overflow-scroll pb-20" lines="none">
        {_.map(questions.history, (el, idx) => (
          <IonItem key={idx} button>
            <IonGrid>
              <IonRow>
                <IonCol size="10">
                  <IonGrid>
                    <IonRow>
                      <div>
                        <IonText color={getRandomColorName()}>{el.title}</IonText>{" "}
                        <br />
                        <IonText className="font-bold text-sm">{el.text}</IonText>
                      </div>
                    </IonRow>
                    <IonRow>
                      {el.code && <IonTextarea className="p-2" disabled rows={getLineCodeLinesHeight(el.code)} value={el.code} />}
                    </IonRow>
                    <IonGrid>
                      {_.map(_.toPairs(el.metrics.datasheet), (e, i) => (
                        <IonRow key={i}>
                          <IonCol size="6">
                            <small color={el.metrics.answer === e[0] ? "success" : "danger"}>{i+1}&#41; <strong>{e[1].percentage}%</strong> {e[0]}</small>
                          </IonCol>
                          <IonCol size="6" className="flex flex-col justify-center items-end">
                            <IonProgressBar
                              color={el.metrics.answer === e[0] ? "success" : "danger"}
                              buffer={e[1].percentage / 100}
                              value={e[1].percentage / 100}
                            />
                          </IonCol>
                        </IonRow>
                      ))}
                    </IonGrid>
                  </IonGrid>
                </IonCol>
                <IonCol size="2" className="flex flex-col justify-start items-center">
                  <strong className="text-slate-600">{(el?.metrics?.submitters as any)["total"]}/{(el?.metrics?.audiance as any)["total"]}</strong>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        ))}
      </IonList>
    </>
  );
});

export default SectionHistory;
