import _ from "lodash";
import AceEditor from "react-ace";
import { routes } from "../apis/routes";
import { Dialog } from "@capacitor/dialog";
import Capacitor from "../utils/Capacitor";
import socketPolling from "../socket-polling";
import Header from "../components/common/Header";
import AuthState from "../utils/common/auth-state";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getEditorTheme } from "../utils/common/helper";
import { IResult } from "@com.xcodeclazz/compile-run-v2";
import { selectedBatch } from "../redux/reducers/batcheState";
import { compiler, errorToast, postBatchIsAllowed } from "../apis";
import RunModal, { IRunModal } from "../components/live/sheets/RunModal";
import EditorState, { ISavedCode } from "../utils/algorithm/editor-state";
import { saveRoomDetails, saveUserCount } from "../redux/reducers/roomState";
import { setCodeTask, setEditorFreezed } from "../redux/reducers/codingState";
import IssueModal, { IIssueModal } from "../components/live/sheets/IssueModal";
import { BatchAttrs, BatchResponse_ShowIsAllowed } from "@com.xcodeclazz/batch";
import { ILanguage } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import { handRightOutline, listOutline, peopleCircleOutline, playOutline, refreshOutline, statsChartOutline } from "ionicons/icons";
import { IonIcon, IonButton, IonSpinner, IonPage, IonContent, IonItem, IonButtons, RefresherEventDetail, IonProgressBar } from "@ionic/react";
import { server_client_count, server_focus_off, server_focus_on, server_heartbeat_lub_dub, server_join, server_submit_code_output } from "../socket-polling-emits";
import { CLIENT_CODING_EVENTS, CLIENT_EVENTS, CLIENT_HEARTBEAT_EVENTS, CLIENT_KICKOUT_EVENTS, CLIENT_ROOM_EVENTS, CLIENT_UI_EVENTS, ICodeRunSchedulePayload } from "@com.xcodeclazz/socket-polling";

let codingStateCp: ICodeRunSchedulePayload | undefined = undefined;
const Meet: React.FC<{
  selected: BatchAttrs;
}> = (props) => {
  const authState = new AuthState();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const batch = new URLSearchParams(location.search)?.get('batch');
  const token = new URLSearchParams(location.search)?.get('token');
  const selected: BatchAttrs = useSelector((state: any) => state.batchState.selected); // can be undefine, if token is present

  const [loading, setLoading] = useState<boolean>(false);
  const [synced, setSynced] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => server_heartbeat_lub_dub({}), getRandomMs());
    return () => clearInterval(interval);
  }, []);

  useEffect(() => getBatch(), []);

  function getRandomMs() {
    const values = [10000, 11000, 12000, 13000, 14000, 15000];
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex];
  };

  function getBatch (event?: CustomEvent<RefresherEventDetail>) {
    if (loading) return;
    const data = {};
    if (batch) _.assign(data, { batch });
    if (token) _.assign(data, { token });
    setLoading(true);
    postBatchIsAllowed(data, (response) => {
      const data: BatchResponse_ShowIsAllowed = response.data;
      if (!data.isAllowed || !data.batch?.isLive) setTimeout(() => history.goBack(), 100);
      else {
        const user = authState.getUser();
        if (user?.name && data.batch) {
          server_join({ name: user.name, roomId: data.batch.id });
          dispatch(saveRoomDetails({ name: user.name, roomId: batch }));
          setSelectedLang(data.batch.meta.lang as ILanguage);
          dispatch(selectedBatch(data.batch));
          setSynced(true);
        } else {
          setTimeout(() => history.goBack(), 100);
        }
      }
      setLoading(false);
      event?.detail?.complete();
    }, (error) => {
      event?.detail?.complete();
      console.log(error);
      errorToast(error);
      setLoading(false);
    });
  }

  // ======================
  // Editor State
  // ======================

  const editorState = new EditorState();
  const runModalRef = React.createRef<IRunModal>();
  const aceEditorRef = React.createRef<AceEditor>();
  const issueModalRef = React.createRef<IIssueModal>();
  const codingState: any = useSelector((state: any) => state.codingState);
  const theme_state: string = useSelector((state: any) => state.uiState.theme);

  const [fontSize, setFontSize] = useState<number>(18);
  const [showGutter, setShowGutter] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<ILanguage>(codingState?.codeTask?.lang as ILanguage || props?.selected?.meta?.lang as ILanguage || ILanguage.Python);
  const [source, setSource] = useState<ISavedCode>(editorState.getSavedCodeFor(selectedLang, '-section'));

  useEffect(() => {}, [theme_state]);
  useEffect(() => { if (props.selected?.meta?.lang) setSelectedLang(props.selected?.meta?.lang as ILanguage); }, [props.selected]);
  useEffect(() => {
    setSource(editorState.getSavedCodeFor(selectedLang, '-section'));
    codingStateCp = codingState.codeTask;
  }, [selectedLang, codingState]);
  useEffect(() => editorState.saveCode(source.key, source), [source?.code]);
  useEffect(() => {
    document.addEventListener('keyup', onHotkeysHandler, false);
    return () => document.removeEventListener('keyup', onHotkeysHandler);
  });

  const raisehandIssue = () => issueModalRef.current?.stageCode(source?.code, runModalRef.current?.getResult());
  const onStageCode = () => runModalRef.current?.stageCode(selectedLang, source?.code);
  const onHotkeysHandler = (e: KeyboardEvent) => { if (e.ctrlKey && e.key === 'x') onStageCode(); };
  const resetCode = async () => {
    const { value } = await Dialog.confirm({ title: 'Reset', message: 'Do you really want to reset everything?' });
    if (value) setSource(editorState.getSampleCodeFor(selectedLang));
  };

  // ======================
  // Editor State
  // ======================

  const kickoutListener = (response: CLIENT_KICKOUT_EVENTS.YIELD_KICKOUT) => { /* Please show an alert instead and then redirect this user */ };
  const lubDubListener = (response: CLIENT_HEARTBEAT_EVENTS.YIELD_LUB_DUB) => { /* Don't do anything special here, cause we don't need to */ };
  const kickoutShoutoutListener = (response: CLIENT_KICKOUT_EVENTS.YIELD_KICKOUT_SHOUTOUT) => Capacitor.toast(response.message, "long");

  const counterListener = (response: CLIENT_ROOM_EVENTS.YIELD_TOTAL_CLIENT_COUNT) => dispatch(saveUserCount({ count: response.count }));
  const userJoinListener = () => {
    server_client_count();
  };

  const roomErrorListener = (message: string) => setSynced(false);
  const codeSubmitTimeoutListener = (response: CLIENT_CODING_EVENTS.YIELD_SUBMIT_TIMEOUT) => Capacitor.toast(response.message, 'long');
  const editorUnFreezListener = (response: CLIENT_UI_EVENTS.YIELD_EDITOR_UNFREEZE) => dispatch(setEditorFreezed(false));
  const editorFreezListener = (response: CLIENT_UI_EVENTS.YIELD_EDITOR_FREEZE) => dispatch(setEditorFreezed(true));
  const pushCodeListener = (response: ICodeRunSchedulePayload) => {
    const editorState = new EditorState();
    const sampleCode = editorState.getSampleCodeFor(response.lang as ILanguage, '-section');
    editorState.saveCode(sampleCode.key, { key: sampleCode.key, lang: sampleCode.lang, code: response.code });
    dispatch(setCodeTask(response));
  };
  const runCodeListener = (response: CLIENT_CODING_EVENTS.YIELD_RUN_CODE) => {
    const editorState = new EditorState();
    const authState = new AuthState();
    const src: ISavedCode = editorState.getSavedCodeFor(codingStateCp?.lang as ILanguage, '-section');
    if (!src.code) return Capacitor.toast(`You have no code in the editor`, 'long');
    compiler({ content: src.code, input: codingStateCp?.input || "", lang: codingStateCp?.lang as ILanguage }, (res: IResult, error) => {
      if (error) console.log(error);
      if (res) {
        server_submit_code_output({
          _id: response.id,
          code: src.code,
          created: Date.now(),
          name: authState.getUser()?.name || "",
          output: res.executionResult?.stdout || res.executionResult?.stderr || "",
          result: codingStateCp?.output ? codingStateCp?.output == (res.executionResult?.stdout || res.executionResult?.stderr) : res.isSucceed,
          information: {
            ms: res.ms
          },
        });
      }
    });
  };

  useEffect(() => {
    socketPolling.on("disconnect", roomErrorListener);
    socketPolling.on(CLIENT_EVENTS.ALL.YIELD_ROOM_ERROR, roomErrorListener);
    socketPolling.on(CLIENT_HEARTBEAT_EVENTS.ALL.YIELD_LUB_DUB, lubDubListener);
    socketPolling.on(CLIENT_KICKOUT_EVENTS.GUEST.YIELD_KICKOUT, kickoutListener);
    socketPolling.on(CLIENT_KICKOUT_EVENTS.GUEST.YIELD_KICKOUT_SHOUTOUT, kickoutShoutoutListener);
    socketPolling.on(CLIENT_ROOM_EVENTS.ALL.YIELD_JOIN, userJoinListener);
    socketPolling.on(CLIENT_ROOM_EVENTS.ALL.YIELD_TOTAL_CLIENT_COUNT, counterListener);
    socketPolling.on(CLIENT_CODING_EVENTS.GUEST.YIELD_SUBMIT_TIMEOUT, codeSubmitTimeoutListener);
    socketPolling.on(CLIENT_UI_EVENTS.GUEST.YIELD_EDITOR_UNFREEZE, editorUnFreezListener);
    socketPolling.on(CLIENT_UI_EVENTS.GUEST.YIELD_EDITOR_FREEZE, editorFreezListener);
    socketPolling.on(CLIENT_CODING_EVENTS.GUEST.YIELD_PUSH_CODE, pushCodeListener);
    socketPolling.on(CLIENT_CODING_EVENTS.GUEST.YIELD_RUN_CODE, runCodeListener);
    return () => {
      socketPolling.off("disconnect", roomErrorListener);
      socketPolling.off(CLIENT_EVENTS.ALL.YIELD_ROOM_ERROR, roomErrorListener);
      socketPolling.off(CLIENT_HEARTBEAT_EVENTS.ALL.YIELD_LUB_DUB, lubDubListener);
      socketPolling.off(CLIENT_KICKOUT_EVENTS.GUEST.YIELD_KICKOUT, kickoutListener);
      socketPolling.off(CLIENT_KICKOUT_EVENTS.GUEST.YIELD_KICKOUT_SHOUTOUT, kickoutShoutoutListener)
      socketPolling.off(CLIENT_ROOM_EVENTS.ALL.YIELD_JOIN, userJoinListener);
      socketPolling.off(CLIENT_ROOM_EVENTS.ALL.YIELD_TOTAL_CLIENT_COUNT, counterListener);
      socketPolling.off(CLIENT_CODING_EVENTS.GUEST.YIELD_SUBMIT_TIMEOUT, codeSubmitTimeoutListener);
      socketPolling.off(CLIENT_UI_EVENTS.GUEST.YIELD_EDITOR_UNFREEZE, editorUnFreezListener);
      socketPolling.off(CLIENT_UI_EVENTS.GUEST.YIELD_EDITOR_FREEZE, editorFreezListener);
      socketPolling.off(CLIENT_CODING_EVENTS.GUEST.YIELD_PUSH_CODE, pushCodeListener);
      socketPolling.off(CLIENT_CODING_EVENTS.GUEST.YIELD_RUN_CODE, runCodeListener);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState === 'visible') server_focus_on({});
      else server_focus_off({});
    });
  }, []);

  const openZoom = () => {
    if (selected.meta.zoomPath) window.open(selected.meta.zoomPath, "_blank");
  };

  const openGoogleMeet = () => {
    if (selected.meta.meetPath) window.open(selected.meta.meetPath, "_blank");
  };

  const openCodeReport = () => {
    if (selected?.id) window.open(routes.GET_CODE_REPORT.replace(":id", selected.id), "_blank");
  };

  return (
    <IonPage>
      <Header />
      <IonContent>
        <IonProgressBar color={synced ? "success" : "danger"} value={1} className="animate-pulse" />
        <IonItem>
          {/* <IonText className="capitalize font-bold">{selectedLang}</IonText> */}
          <IonButtons slot="end">
            {(codingState?.isEditorFreezed || loading) && <IonSpinner name="dots" />}
            <IonButton size="small" title="Show/hide line numbers" fill="clear" onClick={(e) => setShowGutter(!showGutter)}>
              <IonIcon icon={listOutline} slot="icon-only" />
            </IonButton>
            <IonButton size="small" title="Reset" fill="clear" onClick={e => getBatch()}>
              <IonIcon icon={refreshOutline} slot="icon-only" />
            </IonButton>
            <IonButton size="small" title="Test code" fill="clear" onClick={onStageCode}>
              <IonIcon icon={playOutline} slot="icon-only" />
            </IonButton>
            <IonButton size="small" title="Raisehand" fill="clear" onClick={raisehandIssue}>
              <IonIcon icon={handRightOutline} slot="icon-only" />
            </IonButton>
            <IonButton size="small" title="Google Meet Link" fill="clear" onClick={openGoogleMeet}>
              <IonIcon icon={peopleCircleOutline} slot="icon-only" />
            </IonButton>
            {/* <IonButton size="small" title="Zoom Link" fill="clear" onClick={openZoom}>
              <IonIcon icon={videocamOutline} slot="icon-only" />
            </IonButton> */}
            <IonButton size="small" title="Code Report" fill="clear" onClick={openCodeReport}>
              <IonIcon icon={statsChartOutline} slot="icon-only" />
            </IonButton>
            {/* https://code-report.xcodeclazz.com/stage */}
            {/* https://code-report.xcodeclazz.com */}
            {/* <IonButton size="small" title="Code Report" fill="clear" onClick={(e) => {}}>
              <IonIcon icon={megaphoneOutline} slot="icon-only" />
            </IonButton>
            <IonButton size="small" title="Discord" fill="clear" onClick={(e) => {}}>
              <IonIcon icon={logoDiscord} slot="icon-only" />
            </IonButton> */}
          </IonButtons>
        </IonItem>
        <AceEditor
          ref={aceEditorRef}
          name={`ID-${Math.random()}`}
          mode={editorState.getMode(selectedLang)}
          width="100"
          theme={getEditorTheme()}
          fontSize={fontSize}
          highlightActiveLine={true}
          showPrintMargin={true}
          readOnly={codingState?.isEditorFreezed}
          showGutter={showGutter}
          wrapEnabled={false}
          editorProps={{ $blockScrolling: true }}
          onLoad={(editor) => { editor.renderer.setPadding(10) }}
          style={{ height: "90%", backgroundColor: "transparent" }}
          placeholder={`© xCodeClazz 2021 - ${new Date().getFullYear()}`}
          value={`${source?.code}`}
          // debounceChangePeriod={1000}
          onChange={(code: string) => setSource({ ...source, code })}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
        <RunModal ref={runModalRef}  />
        <IssueModal ref={issueModalRef}  />
      </IonContent>
    </IonPage>
  );
};

export default Meet;
