import { CLIENT_CHAT_EVENTS, MessageAttrs } from "@com.xcodeclazz/socket-polling";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const chatState = createSlice({
  name: "chatState",
  initialState: {
    history: [] as MessageAttrs[],
  },
  reducers: {
    saveChatHistory: (state, action) => {
      const chat: CLIENT_CHAT_EVENTS.YIELD_CHAT_MESSAGE = action.payload;
      state.history.unshift(chat);
    },
    saveChatHistories: (state, action) => {
      const data: CLIENT_CHAT_EVENTS.YIELD_CHAT_HISTORY = action.payload;
      if (data.history) state.history = _.reverse(_.cloneDeep(data.history));
    },
    clearChatHistories: (state) => {
      state.history = [];
    },
  },
});

export const {
  saveChatHistory,
  saveChatHistories,
  clearChatHistories,
} = chatState.actions;

export default chatState.reducer;
