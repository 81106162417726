import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducers/counterSlice";
import raisehandState from "./reducers/raisehandState";
import broadcastState from "./reducers/broadcastState";
import questionState from "./reducers/questionState";
import codingState from "./reducers/codingState";
import batchState from "./reducers/batcheState";
import chatState from "./reducers/chatState";
import userState from "./reducers/userState";
import roomState from "./reducers/roomState";
import uiState from "./reducers/uiState";

export default configureStore({
  reducer: {
    uiState: uiState,
    userState: userState,
    chatState: chatState,
    roomState: roomState,
    batchState: batchState,
    counter: counterReducer,
    codingState: codingState,
    questionState: questionState,
    raisehandState: raisehandState,
    broadcastState: broadcastState,
  },
});
