import _ from "lodash";
import Hls from "hls.js";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import React, { useEffect, useRef, useState } from "react";

import "./HLSJs.css";

let lastSeek = 0;
let totalDuration = 0;

const HLSJs: React.FC<{
  selected: BatchAttrs;
}> = ({ selected }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [player, setPlayer] = useState<Hls | null>(null);

  const handleWaiting = () => console.log("waiting"); // videoRef.current?.pause();
  const handleStalled = () => console.log("Playback stalled");
  const handleError = (e: any) => console.error("Media error", e);
  const timeUpdate = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (video.currentTime < 1 && lastSeek > 1 && lastSeek < totalDuration) {
        // means it was running, and was accidently reseted
        if (videoRef.current) videoRef.current.currentTime = lastSeek;
      } else {
        lastSeek = video.currentTime;
        totalDuration = video.duration;
      }
    }
  };

  function loaded() {
    if (videoRef.current) {
      // do something if the video loaded
    }
  }

  useEffect(() => {
    if (!selected?.meta?.rtmpPath) return;
    const video = videoRef.current;
    if (video) video.muted = true;
    // Capacitor.toast("Please unmute the video", "long");

    // video?.addEventListener("playing", playing);
    // video?.addEventListener("pause", paused);
    // video?.addEventListener("ended", ended);
    video?.addEventListener("timeupdate", timeUpdate);
    video?.addEventListener("stalled", handleStalled);
    video?.addEventListener("waiting", handleWaiting);
    video?.addEventListener("error", handleError);
    video?.addEventListener("loadedmetadata", loaded);

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(selected?.meta?.rtmpPath);
      hls.attachMedia(video as HTMLMediaElement);
      hls.on(Hls.Events.FRAG_LOADED, (_, data) => {
        if (video && video.muted) {
          video?.play()?.then(() => console.log("Playing")).catch((error) => console.log("Paused"));
        }
      });
      setPlayer(hls);
    } else if (video && video.canPlayType("application/vnd.apple.mpegurl"))
      video.src = selected?.meta?.rtmpPath;

    return () => {
      // video?.removeEventListener("playing", playing);
      // video?.removeEventListener("pause", paused);
      // video?.removeEventListener("ended", ended);
      video?.removeEventListener("timeupdate", timeUpdate);
      video?.removeEventListener("stalled", handleStalled);
      video?.removeEventListener("error", handleError);
      video?.removeEventListener("waiting", handleWaiting);
      video?.removeEventListener("loadedmetadata", loaded);
      if (Hls.isSupported() && player) player.destroy();
      setPlayer(null);
    };
  }, [selected]);

  return (
    <>
      <div className="live-label">Live</div>
      <video ref={videoRef} controls className="w-full h-full" />
    </>
  );
};

export default HLSJs;
