import React, { useState, useEffect } from "react";

export interface ICountdownText {
  onTimeUp: () => void;
  seconds: number;
}

const CountdownText: React.FC<ICountdownText> = ({ seconds, onTimeUp }) => {
  const [count, setCount] = useState(seconds);
  const [interval, setIntervall] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    let interval = setInterval(() => setCount((prevCount) => prevCount - 1), 1000);
    setIntervall(interval);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (count <= 0) {
      clearInterval(interval);
      onTimeUp();
    }
  }, [count]);

  return <span>{count}</span>;
};

export default CountdownText;
