import _ from "lodash";
import AuthState from "../../../utils/common/auth-state";
import { IResult } from "@com.xcodeclazz/compile-run-v2";
import { closeOutline, sendOutline } from "ionicons/icons";
import { server_raisehand } from "../../../socket-polling-emits";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";

export interface IIssueModal {
  stageCode: (src: string, result: IResult | undefined) => void;
}

const IssueModal = forwardRef<IIssueModal, {}>((props, ref) => {

  const modalInputRef = React.createRef<HTMLIonTextareaElement>();

  const authState = new AuthState();
  const [content, setContent] = useState<string>("");
  const [result, setResult] = useState<IResult | undefined>();
  const [isOpen, setOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    stageCode: (src: string, result: IResult | undefined) => {
      setResult(result);
      setContent(src);
      if (!isOpen) 
        setOpen(true);
    },
  }));

  const sendCode = () => {
    const message = modalInputRef.current?.value?.toString() || "-";
    server_raisehand({
      body: message,
      code: content,
      created: Date.now(),
      name: authState.getUser()?.name || "",
      output: result?.executionResult?.stdout || result?.executionResult?.stderr || "",
    });
    if (!message) modalInputRef.current!.value = "";
    setOpen(false);
  };

  return (
    <IonModal isOpen={isOpen} initialBreakpoint={0.45} breakpoints={[0, 0.25, 0.5, 0.75]} onDidDismiss={(e) => setOpen(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <b>Raisehand</b>
          </IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={(e) => setOpen(false)}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={sendCode}>
              <IonIcon icon={sendOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <section className="flex flex-col space-y-2">
          <IonItem lines="none">
            <IonTextarea
              placeholder="Any message?"
              ref={modalInputRef}
              spellCheck={false}
              autoGrow={true}
            />
          </IonItem>
          <IonItem lines="none">
            <IonTextarea
              wrap="off"
              readonly={true}
              disabled={true}
              autoGrow={true}
              value={content}
            ></IonTextarea>
          </IonItem>
          <IonItem>
            <IonTextarea
              wrap="off"
              readonly={true}
              disabled={true}
              autoGrow={true}
              value={result?.executionResult?.stdout || result?.executionResult?.stderr}
            ></IonTextarea>
          </IonItem>
        </section>
      </IonContent>
    </IonModal>
  );
});

export default IssueModal;
