import { ICodeRunSchedulePayload } from "@com.xcodeclazz/socket-polling";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const codingState = createSlice({
  name: "codingState",
  initialState: {
    isEditorFreezed: false as boolean,
    codeTask: undefined as ICodeRunSchedulePayload | undefined,
  },
  reducers: {
    setEditorFreezed: (state, action) => {
      state.isEditorFreezed = action.payload; // either true or false
    },
    setCodeTask: (state, action) => {
      state.codeTask = action.payload;
    },
    clearCodingStates: (state) => {
      state.isEditorFreezed = false;
      state.codeTask = undefined;
    },
  },
});

export const { 
  setCodeTask,
  setEditorFreezed, 
  clearCodingStates,
} = codingState.actions;

export default codingState.reducer;
