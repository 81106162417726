import _ from "lodash";
import HLSJs from "../components/HLSJs";
import Capacitor from "../utils/Capacitor";
import socketPolling from "../socket-polling";
import { ESegments } from "../apis/definations";
import Header from "../components/common/Header";
import React, { useEffect, useState } from "react";
import AuthState from "../utils/common/auth-state";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import SectionChats from "../components/live/SectionChats";
import { ILanguage } from "@com.xcodeclazz/monolithic-common";
import { selectedBatch } from "../redux/reducers/batcheState";
import SectionHistory from "../components/live/SectionHistory";
import SectionSegments from "../components/live/SectionSegments";
import SectionRaisehand from "../components/live/SectionRaisehand";
import SectionCodingEditor from "../components/live/SectionEditor";
import McqQuestion from "../components/live/questions/McqQuestion";
import { compiler, errorToast, postBatchIsAllowed } from "../apis";
import { saveBroadcastHistory } from "../redux/reducers/broadcastState";
import InputQuestion from "../components/live/questions/InputQuestion";
import AnyoneQuestion from "../components/live/questions/AnyoneQuestion";
import EditorState, { ISavedCode } from "../utils/algorithm/editor-state";
import DescriptionPanel from "../components/live/broadcast/DescriptionPanel";
import { setCodeTask, setEditorFreezed } from "../redux/reducers/codingState";
import { BatchAttrs, BatchResponse_ShowIsAllowed } from "@com.xcodeclazz/batch";
import { saveChatHistories, saveChatHistory } from "../redux/reducers/chatState";
import { IRoomState, saveRoomDetails, saveUserCount } from "../redux/reducers/roomState";
import { saveRaisehandHistories, saveRaisehandHistory } from "../redux/reducers/raisehandState";
import { saveAskedQuestion, saveQuestionHistories, saveQuestionHistory, saveResultQuestion } from "../redux/reducers/questionState";
import { server_chat_history, server_client_count, server_join, server_question_history, server_raisehand_history, server_submit_code_output } from "../socket-polling-emits";
import { CLIENT_BROADCAST_EVENTS, CLIENT_CHAT_EVENTS, CLIENT_CODING_EVENTS, CLIENT_KICKOUT_EVENTS, CLIENT_QUESTION_EVENTS, CLIENT_RAISEHAND_EVENTS, CLIENT_ROOM_EVENTS, CLIENT_UI_EVENTS, EQuestionCategory, ICodeRunSchedulePayload } from "@com.xcodeclazz/socket-polling";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonCard,
  IonPage,
  IonContent,
} from "@ionic/react";

const Live: React.FC<{}> = (props) => {
  const authState = new AuthState();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const batch = new URLSearchParams(location.search)?.get('batch');
  const token = new URLSearchParams(location.search)?.get('token');

  const [loading, setLoading] = useState<boolean>(false);
  const [playable, setPlayable] = useState<boolean>(false);
  const [currentSegment, setCurrentSegment] = useState<ESegments>(ESegments.EDITOR);

  const codingState: any = useSelector((state: any) => state.codingState);
  const roomState: IRoomState = useSelector((state: any) => state.roomState);
  const selected: BatchAttrs = useSelector((state: any) => state.batchState.selected); // can be undefine, if token is present
  const askedQuestion: CLIENT_QUESTION_EVENTS.YIELD_QUESTION = useSelector((state: any) => state.questionState.askedQuestion);
  const resultQuestion: CLIENT_QUESTION_EVENTS.YIELD_RESULT = useSelector((state: any) => state.questionState.resultQuestion);

  useEffect(() => {
    const data = {};
    if (batch) _.assign(data, { batch });
    if (token) _.assign(data, { token });
    setLoading(true);
    postBatchIsAllowed(data, (response) => {
      const data: BatchResponse_ShowIsAllowed = response.data;
      if (!data.isAllowed || !data.batch?.isLive) setTimeout(() => history.goBack(), 100);
      else {
        const user = authState.getUser();
        if (user?.name && data.batch) {
          setPlayable(true);
          server_join({ name: user.name, roomId: data.batch.id });
          dispatch(saveRoomDetails({ name: user.name, roomId: batch }));
          dispatch(selectedBatch(data.batch));
        } else {
          setTimeout(() => history.goBack(), 100);
        }
      }
      setLoading(false);
    }, (error) => {
      console.log(error);
      errorToast(error);
      setLoading(false);
    });
  }, []);

  const messageReplyListener = (response: CLIENT_CHAT_EVENTS.YIELD_CHAT_REPLY) => { console.log(response); };
  const questionResultListener = (response: CLIENT_QUESTION_EVENTS.YIELD_RESULT) => dispatch(saveResultQuestion(response));
  const kickoutListener = (response: CLIENT_KICKOUT_EVENTS.YIELD_KICKOUT) => { /* Please show an alert instead and then redirect this user */ };

  const questionTimeoutListener = (response: CLIENT_QUESTION_EVENTS.YIELD_TIMEOUT) => Capacitor.toast(response.message, "long");
  const questionCancelledListener = (response: CLIENT_QUESTION_EVENTS.YIELD_CANCELLED) => Capacitor.toast(response.message, "long");
  const kickoutShoutoutListener = (response: CLIENT_KICKOUT_EVENTS.YIELD_KICKOUT_SHOUTOUT) => Capacitor.toast(response.message, "long");

  const newChatListener = (response: CLIENT_CHAT_EVENTS.YIELD_CHAT_MESSAGE) => dispatch(saveChatHistory(response));
  const chatsHistoryListener = (response: CLIENT_CHAT_EVENTS.YIELD_CHAT_HISTORY) => dispatch(saveChatHistories(response));
  const questionAskedListener = (response: CLIENT_QUESTION_EVENTS.YIELD_QUESTION) => dispatch(saveAskedQuestion(response));
  const broadcastListener = (response: CLIENT_BROADCAST_EVENTS.YIELD_BROADCAST) => dispatch(saveBroadcastHistory(response));
  const newRaisehandListener = (response: CLIENT_RAISEHAND_EVENTS.YIELD_RAISEHAND) => dispatch(saveRaisehandHistory(response));
  const questionReportListener = (response: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_REPORT) => dispatch(saveQuestionHistory(response));
  const counterListener = (response: CLIENT_ROOM_EVENTS.YIELD_TOTAL_CLIENT_COUNT) => dispatch(saveUserCount({ count: response.count }));
  const questionHistoryListener = (response: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_HISTORY) => dispatch(saveQuestionHistories(response));
  const raisehandHistoryListener = (response: CLIENT_RAISEHAND_EVENTS.YIELD_RAISEHAND_HISTORY) => dispatch(saveRaisehandHistories(response));
  const userJoinListener = () => {
    server_raisehand_history();
    server_question_history();
    server_client_count();
    server_chat_history();
  };

  const codeSubmitTimeoutListener = (response: CLIENT_CODING_EVENTS.YIELD_SUBMIT_TIMEOUT) => Capacitor.toast(response.message, 'long');
  const editorUnFreezListener = (response: CLIENT_UI_EVENTS.YIELD_EDITOR_UNFREEZE) => dispatch(setEditorFreezed(false));
  const editorFreezListener = (response: CLIENT_UI_EVENTS.YIELD_EDITOR_FREEZE) => dispatch(setEditorFreezed(true));
  const pushCodeListener = (response: ICodeRunSchedulePayload) => {
    const editorState = new EditorState();
    const sampleCode = editorState.getSampleCodeFor(response.lang as ILanguage, '-section');
    editorState.saveCode(sampleCode.key, { key: sampleCode.key, lang: sampleCode.lang, code: response.code });
    dispatch(setCodeTask(response));
  };
  const runCodeListener = (response: CLIENT_CODING_EVENTS.YIELD_RUN_CODE) => {
    const editorState = new EditorState();
    const authState = new AuthState();
    const src: ISavedCode = editorState.getSavedCodeFor(codingState?.codeTask?.lang as ILanguage, '-section');
    if (!src.code) return Capacitor.toast(`You have no code in the editor`, 'long');
    compiler({ content: src.code, input: codingState?.codeTask?.input || "", lang: codingState?.codeTask?.lang }, (res, error) => {
      if (error) console.log(error);
      if (res) {
        server_submit_code_output({
          _id: response.id,
          code: src.code,
          created: Date.now(),
          result: res.isSucceed,
          name: authState.getUser()?.name || "",
          output: res.executionResult.stdout + res.executionResult.stderr,
          information: {
            ms: res.ms
          },
        });
      }
    });
    // todo: later we'll ask user to install our app which will use native compilers
  };

  useEffect(() => {
    socketPolling.on(CLIENT_KICKOUT_EVENTS.GUEST.YIELD_KICKOUT, kickoutListener);
    socketPolling.on(CLIENT_KICKOUT_EVENTS.GUEST.YIELD_KICKOUT_SHOUTOUT, kickoutShoutoutListener);
    socketPolling.on(CLIENT_QUESTION_EVENTS.GUEST.YIELD_RESULT, questionResultListener);
    socketPolling.on(CLIENT_QUESTION_EVENTS.GUEST.YIELD_TIMEOUT, questionTimeoutListener);
    socketPolling.on(CLIENT_QUESTION_EVENTS.GUEST.YIELD_QUESTION, questionAskedListener);
    socketPolling.on(CLIENT_QUESTION_EVENTS.GUEST.YIELD_CANCELLED, questionCancelledListener);
    socketPolling.on(CLIENT_QUESTION_EVENTS.ALL.YIELD_QUESTION_REPORT, questionReportListener);
    socketPolling.on(CLIENT_QUESTION_EVENTS.ALL.YIELD_QUESTION_HISTORY, questionHistoryListener);
    socketPolling.on(CLIENT_BROADCAST_EVENTS.ALL.YIELD_BROADCAST, broadcastListener);
    socketPolling.on(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_REPLY, messageReplyListener);
    socketPolling.on(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_MESSAGE, newChatListener);
    socketPolling.on(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_HISTORY, chatsHistoryListener);
    socketPolling.on(CLIENT_ROOM_EVENTS.ALL.YIELD_JOIN, userJoinListener);
    socketPolling.on(CLIENT_ROOM_EVENTS.ALL.YIELD_TOTAL_CLIENT_COUNT, counterListener);
    socketPolling.on(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND, newRaisehandListener);
    socketPolling.on(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND_HISTORY, raisehandHistoryListener);
    socketPolling.on(CLIENT_CODING_EVENTS.GUEST.YIELD_SUBMIT_TIMEOUT, codeSubmitTimeoutListener);
    socketPolling.on(CLIENT_UI_EVENTS.GUEST.YIELD_EDITOR_UNFREEZE, editorUnFreezListener);
    socketPolling.on(CLIENT_UI_EVENTS.GUEST.YIELD_EDITOR_FREEZE, editorFreezListener);
    socketPolling.on(CLIENT_CODING_EVENTS.GUEST.YIELD_PUSH_CODE, pushCodeListener);
    socketPolling.on(CLIENT_CODING_EVENTS.GUEST.YIELD_RUN_CODE, runCodeListener);
    return () => {
      socketPolling.off(CLIENT_KICKOUT_EVENTS.GUEST.YIELD_KICKOUT, kickoutListener);
      socketPolling.off(CLIENT_KICKOUT_EVENTS.GUEST.YIELD_KICKOUT_SHOUTOUT, kickoutShoutoutListener);
      socketPolling.off(CLIENT_QUESTION_EVENTS.GUEST.YIELD_RESULT, questionResultListener);
      socketPolling.off(CLIENT_QUESTION_EVENTS.GUEST.YIELD_TIMEOUT, questionTimeoutListener);
      socketPolling.off(CLIENT_QUESTION_EVENTS.GUEST.YIELD_QUESTION, questionAskedListener);
      socketPolling.off(CLIENT_QUESTION_EVENTS.GUEST.YIELD_CANCELLED, questionCancelledListener);
      socketPolling.off(CLIENT_QUESTION_EVENTS.ALL.YIELD_QUESTION_REPORT, questionReportListener);
      socketPolling.off(CLIENT_QUESTION_EVENTS.ALL.YIELD_QUESTION_HISTORY, questionHistoryListener);
      socketPolling.off(CLIENT_BROADCAST_EVENTS.ALL.YIELD_BROADCAST, broadcastListener);
      socketPolling.off(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_REPLY, messageReplyListener);
      socketPolling.off(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_MESSAGE, newChatListener);
      socketPolling.off(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_HISTORY, chatsHistoryListener);
      socketPolling.off(CLIENT_ROOM_EVENTS.ALL.YIELD_JOIN, userJoinListener);
      socketPolling.off(CLIENT_ROOM_EVENTS.ALL.YIELD_TOTAL_CLIENT_COUNT, counterListener);
      socketPolling.off(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND, newRaisehandListener);
      socketPolling.off(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND_HISTORY, raisehandHistoryListener);
      socketPolling.off(CLIENT_CODING_EVENTS.GUEST.YIELD_SUBMIT_TIMEOUT, codeSubmitTimeoutListener);
      socketPolling.off(CLIENT_UI_EVENTS.GUEST.YIELD_EDITOR_UNFREEZE, editorUnFreezListener);
      socketPolling.off(CLIENT_UI_EVENTS.GUEST.YIELD_EDITOR_FREEZE, editorFreezListener);
      socketPolling.off(CLIENT_CODING_EVENTS.GUEST.YIELD_PUSH_CODE, pushCodeListener);
      socketPolling.off(CLIENT_CODING_EVENTS.GUEST.YIELD_RUN_CODE, runCodeListener);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <IonPage>
      <Header themeBtn />
      <IonContent fullscreen>
        <IonGrid className="h-full overflow-hidden">
          <IonRow className="h-full overflow-hidden">
            <IonCol size="12" sizeMd="7" sizeLg="7" sizeXl="8">
              <IonCard className="h-full w-full ion-no-margin rounded-lg">
                <IonGrid className="h-full overflow-hidden ion-no-padding">
                  <IonRow className="h-[70%]">
                    <IonCard className="w-full h-full ion-no-margin rounded-none">
                      <HLSJs selected={selected} />
                    </IonCard>
                  </IonRow>
                  <IonRow className="h-[30%]">
                    {!askedQuestion && <DescriptionPanel selected={selected} />}
                    {askedQuestion && askedQuestion.category === EQuestionCategory.Choice && <AnyoneQuestion askedQuestion={askedQuestion} selected={selected} />}
                    {askedQuestion && askedQuestion.category === EQuestionCategory.Input && <InputQuestion askedQuestion={askedQuestion} selected={selected} />}
                    {askedQuestion && askedQuestion.category === EQuestionCategory.Mcq && <McqQuestion askedQuestion={askedQuestion} selected={selected} />}
                    {/* {resultQuestion && <h1>Your answer</h1>} */}
                  </IonRow>
                </IonGrid>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeMd="5" sizeLg="5" sizeXl="4" className="h-full overflow-hidden">
              <IonCard className="h-full w-full ion-no-margin rounded-lg">
                <SectionSegments currentSegment={currentSegment} onSegmentChange={(e) => setCurrentSegment(e?.toString() as ESegments)} />
                {currentSegment === ESegments.RAISEHAND && <SectionRaisehand selected={selected} />}
                {currentSegment === ESegments.EDITOR && <SectionCodingEditor selected={selected} />}
                {currentSegment === ESegments.HISTORY && <SectionHistory selected={selected} />}
                {currentSegment === ESegments.CHATS && <SectionChats selected={selected} />}
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Live;
