import _ from "lodash";

export default class FingerprintState {
  savePrint(fp: string): void {
    this.save("f", fp);
  }
  getPrint(): string {
    return this.get("f") || "";
  }

  get(key: string): string | null {
    if (localStorage.getItem(key)) return localStorage.getItem(key);
    return null;
  }

  save(key: string, value: any): void {
    if (value == null) return;
    localStorage.setItem(key, value);
  }

  delete = (key: string) => localStorage.removeItem(key);
  clean = () => localStorage.clear();
}
