import _ from "lodash";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import PrivateRoute from "./components/PrivateRoute";
import NonPrivateRoute from "./components/NonPrivateRoute";

import { homeOutline, settingsOutline } from "ionicons/icons";
import { IonApp, IonTabs, IonIcon, IonLabel, IonLoading, IonTabBar, IonSpinner, IonTabButton, setupIonicReact, IonRouterOutlet } from "@ionic/react";

import Home from "./pages/Home";
import Auth from "./pages/Auth";
import Live from "./pages/Live";
import Meet from "./pages/Meet";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";

import ApplicationContextProvider from "./data/ApplicationContextProvider";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Ace editor styles */
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-json5";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-jsx";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/ext-prompt";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-tomorrow_night_bright";
import "ace-builds/src-noconflict/theme-xcode";

import "brace";
import "brace/ext/searchbox";
import "brace/ext/language_tools";

/* Theme variables */
import "./theme/variables.css";
import "./theme/theme.css";

setupIonicReact();

export const currentPath = () => {
  const match = window.location.pathname.match(/^\/[^?#]*/);
  return match ? match[0] : "";
}
export const components = {
  home: {
    path: "/",
    Component: Home,
  },
  auth: {
    path: "/auth",
    Component: Auth,
  },
  dashboard: {
    path: "/dashboard",
    Component: Dashboard,
  },
  live: {
    path: "/live",
    Component: Live,
  },
  meet: {
    path: "/meet",
    Component: Meet,
  },
  login: {
    path: "/login",
    Component: Login,
  },
  settings: {
    path: "/settings",
    Component: Settings,
  },
};
const App: React.FC = () => {
  const [wait, setWait] = useState<boolean>(true);
  const tabBarVisible = useSelector((state: any) => state.uiState.tabBarVisible);

  useEffect(() => {
    const displayChanges = () => setWait(false);
    window.addEventListener('storage', displayChanges);
    return () => window.removeEventListener('storage', displayChanges);
  }, []);

  const getRoutes = () => {
    return (
      <IonRouterOutlet id="main-drawer" animated={false}>
        <NonPrivateRoute path={components.auth.path} component={components.auth.Component} redirect={components.dashboard.path} exact />
        <PrivateRoute path={components.live.path} component={components.live.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.meet.path} component={components.meet.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.dashboard.path} component={components.dashboard.Component} redirect={components.auth.path} exact />

        {/* Open routes */}
        {/* <Route path={components.settings.path} component={components.settings.Component} exact /> */}
        {/* <Route path={components.home.path} component={components.home.Component} exact /> */}
        <Redirect to={components.auth.path} />
      </IonRouterOutlet>
    );
  };

  return (
    <IonApp>
      {wait && <IonLoading isOpen message="Please wait" duration={5000} />}
      <ApplicationContextProvider>
        <IonReactRouter>
          {getRoutes()}
        </IonReactRouter>
      </ApplicationContextProvider>
    </IonApp>
  )
};

export default App;
