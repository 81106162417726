import _ from 'lodash';
import { routes } from './routes';
import Capacitor from '../utils/Capacitor';
import AuthState from '../utils/common/auth-state';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { ILanguage  } from '@com.xcodeclazz/monolithic-common/build/constants/questions';
import { CompilersPayload_Cpp, CompilersPayload_Java, CompilersPayload_Python, CompilersPayload_Node } from '@com.xcodeclazz/compile-run-v2';
import { BatchPayload_Login, BatchPayload_ShowBatch, BatchPayload_ShowBatchByCourse, BatchPayload_ShowBatchStrength, BatchPayload_ShowIsAllowed } from "@com.xcodeclazz/batch";

const authState = new AuthState();

const handle = (response: HttpResponse, cb: (response: HttpResponse) => void) => {
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode <= 399) return cb(response);
    else if (statusCode >= 400 && statusCode <= 499) throw { response };
    else if (statusCode >= 500 && statusCode <= 599) throw { response };
    throw { response };
};

export const postJavaCompiler = _.debounce(( data: CompilersPayload_Java, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COMPILERS_JAVA,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCppCompiler = _.debounce(( data: CompilersPayload_Cpp, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COMPILERS_CPP,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postNodeCompiler = _.debounce(( data: CompilersPayload_Node, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COMPILERS_NODE,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postPythonCompiler = _.debounce(( data: CompilersPayload_Python, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COMPILERS_PYTHON,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postBatchLogin = _.debounce(( data: BatchPayload_Login, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_BATCH_LOGIN,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postBatchIsAllowed = _.debounce(( data: BatchPayload_ShowIsAllowed, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_BATCH_ISALLOWED,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getBatchMyBatches = _.debounce((cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_BATCH_MY_BATCHES,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getBatchBatchStrength = _.debounce(( data: BatchPayload_ShowBatchStrength, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_BATCH_BATCH_STRENGTH.replace(":batch", data.batch),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getBatchBatchByCourse = _.debounce(( data: BatchPayload_ShowBatchByCourse, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_BATCH_BATCH_BY_COURSE.replace(":course", data.course),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getBatchShowBatch = _.debounce(( data: BatchPayload_ShowBatch, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_BATCH_SHOW_BATCH.replace(":batch", data.batch),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getRandomProgrammingJoke = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: "https://official-joke-api.appspot.com/jokes/programming/random",
    }).then(e => handle(e, cb)).catch(err);
});

export const compiler = (d: { content: string, input: string, lang: ILanguage }, cb: (result: any, error: any) => void) => {
  switch (d.lang) {
    case ILanguage.Cpp:
      return postCppCompiler({ input: d.input, sources: [{ content: d.content, main: true, name: "main.cpp" }] }, (res) => cb(res.data.result, null), (error) => cb(null, error));
    case ILanguage.Java:
      return postJavaCompiler({ input: d.input, sources: [{ content: d.content, main: true, name: "Main.java" }] }, (res) => cb(res.data.result, null), (error) => cb(null, error));
    case ILanguage.Python:
      return postPythonCompiler({ input: d.input, sources: [{ content: d.content, main: true, name: "main.py" }] }, (res) => cb(res.data.result, null), (error) => cb(null, error));
    case ILanguage.Javascript:
      return postNodeCompiler({ input: d.input, sources: [{ content: d.content, main: true, name: "main.js" }] }, (res) => cb(res.data.result, null), (error) => cb(null, error));
  }
};

export const errorToast = _.debounce((error: any) => {
    // @ts-ignore
    const message = _.first(error.response?.data.errors)?.message;
    if (message) Capacitor.toast(message, "long");
});

const commonHeader = () => {
    return {
        'Content-Type': 'application/json',
        'base64': authState.getToken() || '',
    };
}