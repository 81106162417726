import _ from "lodash";
import { BatchAttrs } from "@com.xcodeclazz/batch";
import { server_answer } from "../../../socket-polling-emits";
import { QuestionAttrs } from "@com.xcodeclazz/socket-polling";
import { forwardRef, useImperativeHandle, useState } from "react";
import {
  IonCard,
  IonItem,
  IonRadio,
  IonButton,
  IonCardTitle,
  IonCardHeader,
  IonRadioGroup,
  IonCardContent,
  IonCardSubtitle,
  RadioGroupCustomEvent,
} from "@ionic/react";

export interface IAnyoneQuestionIPC {}

const AnyoneQuestion = forwardRef<
  IAnyoneQuestionIPC,
  {
    askedQuestion: QuestionAttrs,
    selected: BatchAttrs,
  }
>((props, ref) => {
  useImperativeHandle(ref, () => ({}));

  const [selected, setSelected] = useState<string>("");
  const [index, setIndex] = useState<number>(-1);

  const changeHandler = _.debounce((e: RadioGroupCustomEvent) => setSelected(e.detail.value));
  const onSelectIndex = _.debounce((e: number) => setIndex(e));

  const onSubmitHandler = () => {
    if (selected) server_answer({ answer: index.toString(), qid: props.askedQuestion.id });
  };

  return (
    <IonCard className="w-full h-full ion-no-margin rounded-none">
      <IonCardHeader>
        <IonCardTitle>
          <section className="flex flex-row justify-between items-center">
            {props.askedQuestion.text}
            <IonButton onClick={onSubmitHandler} size="small" fill="clear">Submit</IonButton>
          </section>
        </IonCardTitle>
        {/* <IonTextarea value={props.askedQuestion.code} className="w-full" /> */}
        <IonCardSubtitle>Scroll the options if required</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className="h-48 overflow-scroll">
        <IonRadioGroup allowEmptySelection={true} onIonChange={changeHandler} value={selected}>
          {_.map(props.askedQuestion.choice, (el, idx) => (
            <IonItem key={idx}>
              <IonRadio value={el.text} onClick={e => onSelectIndex(idx)}>
                <pre className="p-0 m-0" dangerouslySetInnerHTML={{ __html: el.text }} />
              </IonRadio>
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonCardContent>
    </IonCard>
  );
});

export default AnyoneQuestion;
