import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import {
  QuestionAttrs,
  CLIENT_QUESTION_EVENTS,
} from "@com.xcodeclazz/socket-polling";

export const questionState = createSlice({
  name: "questionState",
  initialState: {
    templates: [] as any[],
    history: [] as QuestionAttrs[],
    askedQuestion: undefined as QuestionAttrs | undefined,
    resultQuestion: undefined as CLIENT_QUESTION_EVENTS.YIELD_RESULT | undefined,
  },
  reducers: {
    saveQuestionHistory: (state, action) => {
      const question: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_REPORT = action.payload;
      state.history.unshift(question);
      state.askedQuestion = undefined;
    },
    saveQuestionHistories: (state, action) => {
      const data: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_HISTORY = action.payload;
      if (data.history) state.history = _.reverse(_.cloneDeep(data.history));
    },
    saveResultQuestion: (state, action) => {
      state.resultQuestion = action.payload;
    },
    saveAskedQuestion: (state, action) => {
      state.askedQuestion = action.payload;
    },
    saveQuestionsTemplates: (state, action) => {
      state.templates = action.payload;
    },
    clearQuestionHistories: (state) => {
      state.history = [];
    },
  },
});

export const {
  saveAskedQuestion,
  saveResultQuestion,
  saveQuestionHistory,
  saveQuestionHistories,
  clearQuestionHistories,
  saveQuestionsTemplates,
} = questionState.actions;

export default questionState.reducer;
